// layout

// Original colours
// $bg-layout1: #FBF8F1;
// $bg-layout2: #F7ECDE;
// $bg-layout3: #E9DAC1;
// $bg-layout4: #54BAB9;

// Party-specific colours
// $bg-layout1: #F4F5F4; 
// $bg-layout2: #EAECEA; 
// $bg-layout3: #DFE2DF; 
// $bg-layout4: #46AFAD;

$bg-layout1: #F2EFEA;
$bg-layout2: #EFECE6;
$bg-layout3: #E7E3DA;
$bg-layout4: #46AFAD;
$c-bad: #B71106;
$c-good: #034732;
$c-good: darken($bg-layout4, 15);
$c-good: #2E8A40;

// $bg-layout1: #F6F5F3;
// $bg-layout2: #EDECE8;
// $bg-layout3: #E4E3DD;

// Bootstrap theme variables
$theme-colors: (
  'primary': $bg-layout4,
  //darken($bg-layout3, 30),
  'secondary':rgb(0, 0, 0, 0.55),
  'success':#015668,
  'danger':#FF304F,
  'info': darken($bg-layout3, 30),
  //#0F81C7,
  'warning':#0DE2EA,
  'light': white,
  //#e8e8e8,
  'dark':#000000,
);

$component-active-color: $bg-layout4;
$component-active-bg: rgba($bg-layout4, 0.2);

// $form-range-track-bg: rgb(0,0,0,0.1);


@import url(https://fonts.googleapis.com/css?family=Red+Hat+Display:200,300,400,500,600,700);
$headings-font-family: 'Red Hat Display';

@import '~bootstrap/scss/bootstrap';
@import 'colors.module.scss';

h2,
h3 {
  font-weight: bold;
}

h2 {
  line-height: 1.6em;
}

.header {
  padding-left: 1.5em;
  padding-top: 1.5em;
  padding-bottom: 0.5em;
  color: white;
}

.header a {
  color: white;
  text-decoration: none;
}

.header img.header-logo {
  padding-right: 1rem;
  height: 3rem;
}

.header .header-text {
  vertical-align: middle;
}

.btn-light:hover {
  background-color: #f1f1f1;
  transition: background-color 0.2s ease;
}

.controls,
.results-header,
.results,
.summary {
  padding: 2em;
}

@media (max-width: 576px) {
  .header {
    padding-left: 0.4em;
    padding-top: 1em;
  }

  .results {
    padding: 1.2em;
  }
}

.call-to-action {
  margin-top: 0.8em;
}

.call-to-action .btn {
  width: 16em;
}

.call-to-action .btn:not(:hover) {
  // background:
  //   linear-gradient(60deg, $bg-lewica 33%, rgba(0, 0, 0, 0) 33%),
  //   linear-gradient(60deg, $bg-ko 66%, $bg-trzecia_droga 66%);
}

.call-to-action-intro {
  font-size: 1.1em;
  font-weight: 500;
  display: block;
  padding: 0.5em;
}

.accordion-flush .accordion-item {
  background-color: transparent;
  border: none;
}

.accordion-flush .accordion-button {
  border-top: 1px solid #00000010;
  padding: 0;
  font-size: 1.2rem;
  color: rgba(black, 0.7);
  background: linear-gradient(90deg, lighten($bg-layout1, 2) 0%, $bg-layout1 100%);
  box-shadow: none;
}

.accordion-body .form-switch {
  padding-left: 1.5em;
}

.start-here-icon {
  color: darken($bg-layout3, 20);
  font-size: 0.7em;
  padding-left: 1rem;
  padding-right: 1rem;
}

.header-number {
  color: darken($bg-layout3, 30);
  padding-right: 0.5rem;
}

p.description {
  opacity: 0.9;
  font-size: 0.9rem;
  padding: 0;
  margin: 0.5rem;
  margin-right: 0;
}

p.description .info-icon,
.header-icon {
  color: darken($bg-layout3, 10);
}

.header-icon {
  padding-right: 0.5rem;
  color: darken($bg-layout3, 10);
}

.summary p.description .info-icon {
  color: darken($bg-layout3, 15);
}

.header {
  background-color: $bg-layout4;
}

.controls {
  background-color: $bg-layout1;
}

.questions {
  background-color: $bg-layout1;
  padding-top: 2em;
}

.footer {
  background-color: $bg-layout4;
  padding: 2em;
  color: white;
}

.footer a {
  color: white;
}

.controls .government-radio-msg {
  opacity: 0.5;
}

.controls .control-row {
  background: linear-gradient(270deg, lighten($bg-layout1, 3) 40%, $bg-layout1 100%);
  border-left: 2px solid;
}

.controls .control-row .party-score-input {
  text-align: right;
}

.controls .party-score-arrows {
  position: relative;
  width: 1rem;
  margin-right: 0.6rem;
}

.controls .party-score-input-arrow {
  position: absolute;
  padding: 0 0.2rem;
  height: 50%;
  margin: 0;
  margin-top: -0.1rem;
  margin-left: 0.2rem;
  font-size: 0.8rem;
  background-color: rgba(black, 0.05);
  color: rgba(black, 0.5);
}

.controls .party-score-input-arrow:focus {
  outline: none;
  box-shadow: none;
}

.controls .party-score-input-arrow-up {
  top: 0;
}

.controls .party-score-input-arrow-down {
  top: 50%;
}

.controls .input-group {
  height: 2.5rem;
}

.controls .input-group-text {
  padding: 0.2em;
  background-color: rgba(black, 0.05);
}

.controls .tooltip-icon,
.wasted-outcomes .tooltip-icon {
  color: rgba(black, 0.6);
}

.controls .load-2019-results-button {
  margin-left: 1rem;
}

.primary-nav-button {
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
}

.tooltip-inner {
  max-width: 320px;
  text-align: left;
  background-color: #444;
  color: white;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
}

.tooltip p:last-child {
  margin-bottom: 0;
}

.tooltip.show {
  opacity: 1;
}

.results-header {
  background-color: $bg-layout2;
}

.results h3 {
  padding-bottom: 0.5em;
}

.results {
  background-color: $bg-layout2;
}

.results-export {
  background: linear-gradient(180deg, #f6fdfd 80%, darken(#eef7f7, 4) 100%) !important;
}

.results-export .card {
  background: rgba(255, 255, 255, 0.4) !important;
}

.results .score-chart {
  height: 6rem;
}

.summary .seat-chart {
  height: 3rem;
}

.results .seat-chart {
  height: 8rem;
}

.results .constituency-chart {
  height: 60rem;
}

.results .legend {
  padding-bottom: 1em;
  padding-left: 0;
  padding-top: 0.5em;
  text-align: center;
}

@include media-breakpoint-up(sm) {
  .results .legend {
    padding-left: 120px;
  }
}

.constituency-overview .constituency-seat-chart {
  height: 4rem;
}

.constituency-overview .constituency-score-chart {
  height: 3rem;
}

.constituency-overview .constituency-details {
  height: 2em;
  line-height: 2em;
  margin-bottom: 0.1rem;
}

.constituency-name h4 {
  font-size: 1.4rem;
  margin-bottom: 0;
}

.constituency-overview .constituency-number {
  background-color: rgba(black, 0.03);
  padding: 0 0.4rem;
  font-size: 1.2rem;
  display: inline-block;
  text-align: center;
  color: rgba(black, 0.5);
  border-radius: 0.2rem;
}

.text-opposition {
  color: darken($bg-layout4, 5);
}

.post-graphic .results .legend {
  padding-left: 0 !important;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.summary {
  background-color: $bg-layout3;
}

.good-msg,
.bad-msg,
.summary .highlight {
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.good-msg,
.bad-msg {
  margin-bottom: 0.5rem;
  margin-left: 1.35rem;
  text-indent: -1.35rem;
}

.good-msg {
  color: $c-good;
}

.bad-msg {
  color: $c-bad;
}

.good-msg .icon,
.bad-msg .icon {
  padding-right: 0.5rem;
}

.messages-details .good-msg,
.messages-details .bad-msg {
  font-size: 1rem;
}

.messages-details li {
  display: inline-block;
  margin-right: 1rem;
}

.seat-cards .card {
  background-color: lighten($bg-layout2, 3);
  text-align: center;
  padding: 0;
}

.seat-cards .card-body {
  padding: 0.6rem;
}

.seat-cards .card-header {
  font-size: 1rem;
  text-align: center;
}

.seat-cards .party-pis {
  color: white !important;
  background-color: $bg-pis;
}

.seat-cards .party-konfederacja {
  color: white !important;
  background-color: $bg-konfederacja;
}

.seat-cards .party-opozycja {
  color: white !important;
  // background-color: $bg-opozycja;
  // background:
  //   linear-gradient(110deg, #000 33%, rgba(0, 0, 0, 0) 33%),
  //   linear-gradient(110deg, #dd0000 66%, #ffc608 66%);
  background:
    linear-gradient(60deg, $bg-lewica 33%, rgba(0, 0, 0, 0) 33%),
    linear-gradient(60deg, $bg-ko 66%, $bg-trzecia_droga 66%);
}

.seat-cards .card-text {
  font-size: 1.8em;
  font-weight: 500;
}

.small-seat-cards .card {
  margin-bottom: 0.2rem;
}

.small-seat-cards .card .card-header,
.small-seat-cards .card .card-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.small-seat-cards .card .card-text {
  font-size: 1em;
  font-weight: 500;
}

.small-seat-cards .card .card-header {
  font-size: 0.8rem;
}

.small-seat-cards .card .card-header,
.small-seat-cards .card .card-body {
  padding: 0.1rem;
}

.summary .card {
  background-color: lighten($bg-layout3, 3);
}

.summary .card-body {}

.outcomes .card-header,
.hypotheticals .card-header,
.wasted-outcomes .card-header {
  color: black;
  background-color: darken($bg-layout3, 8);
}

.wasted-outcomes li {
  line-height: 1.3rem;
  margin-bottom: 0.6rem;
  padding-left: 1.4rem;
  text-indent: -1.4rem;
}

.wasted-outcomes li:first-child {
  margin-top: 0;
}

.wasted-outcomes li:last-child {
  margin-bottom: 0;
}

.wasted-outcomes .wasted-constituencies {
  font-size: 0.8rem;
  line-height: 1rem;
  margin-top: 0.2rem;
  display: block;
  text-indent: 0;
}

.outcomes .card-body {
  padding-bottom: 0;
}

.hypotheticals li {
  padding-left: 1em;
  padding-top: 0.3em;
}

.hypotheticals li:first-child {
  padding-top: 0.7em;
}

.hypotheticals .card-body {
  padding: 1;
}

.hypotheticals .card-body strong {
  color: black;
}

.social p a svg {
  margin-right: 0.2em;
}

.social p,
.sharing p {
  margin: 0;
  margin-top: 1.5em;
  margin-right: 0.2em;
  opacity: 0.75;
  color: rgb(0, 0, 0, 0.5);
}

.social p {
  color: rgb(0, 0, 0, 0.4);
  text-align: center;
}

.social p a {
  color: rgb(0, 0, 0, 0.5);
}

.social p a:hover {
  color: rgb(0, 0, 0, 0.5);
  color: $bg-layout4;
  transition: color 0.2s ease;
}

.sharing p {
  text-align: right;
}

.share-buttons {
  font-size: 1.2rem;
}

.share-buttons button {
  margin-left: 0.5rem;
  vertical-align: middle;
}

.sharing .share-buttons svg:hover,
.explanation-sharing .share-buttons svg:hover,
.explanation-sharing-sticky .share-buttons svg:hover {
  color: $bg-layout4;
  transition: color 0.2s ease;
}

.explanation-sharing .share-buttons {
  font-size: 1.6rem;
}

.explanation-sharing-sticky {
  background-color: rgba(lighten($bg-layout2, 3), 0.6);
}

.footer .share-buttons svg:hover,
.footer a:hover {
  color: rgb(0, 0, 0, 0.5);
  transition: color 0.2s ease;
}

.footer .site-version {
  opacity: 0.5;
}

// internal

$pg-margin: 50px;
$pg-margin-box: 60px;

.post-graphic {
  width: 1080px;
  height: 1350px;
  background-image: url('../images/post_background.png');
  background-size: contain;
  margin: 0;
  padding: 0;
  position: relative;
}

.pg-text {
  font-family: 'Red Hat Display';
  color: white;
}

.pg-single-group,
.pg-all-separate {
  position: absolute;
  left: $pg-margin-box;
  right: $pg-margin-box;
  box-shadow: 0 0 1em rgb(0, 0, 0, 0.2);
}

.pg-single-group {
  bottom: $pg-margin;
}

.pg-all-separate {
  top: 18%;
}

.pg-header {
  position: absolute;
  right: $pg-margin;
  top: $pg-margin-box;
  font-size: 42px;
}

.pg-title,
.pg-subtitle,
.pg-results-header {
  position: absolute;
  left: $pg-margin;
  width: 90%;
}

.pg-title {
  font-size: 26px;
  top: $pg-margin-box;
}

.pg-subtitle {
  font-size: 26px;
  color: rgb(255, 255, 255, 0.6);
  top: $pg-margin-box + 30px;
}

.pg-results-header {
  font-size: 26px;
  top: 190px;
}

.pg-data-source {
  font-size: 15px;
  position: absolute;
  right: $pg-margin;
  left: $pg-margin;
  bottom: $pg-margin - 35px;
  color: rgb(255, 255, 255, 0.7);
  text-align: right;
}

// generic party styles

.controls .p-icon {
  font-size: 1.2rem;
}

.controls .p-name {
  font-size: 1.2rem;
  padding-left: 0.5em;
  color: black;
}

.controls input[type="number"] {
  text-align: right;
}

.legend .p-icon {
  font-size: 0.8rem;
}

.legend .p-name {
  font-size: 0.8rem;
  padding-left: 0.4rem;
}

.legend .p-value {
  font-size: 0.8rem;
  // padding-left: 0.2rem;
  opacity: 0.5;
}

.wasted-outcomes .p-icon {
  margin-right: 0.1em;
}

// parties and groups

// PiS
.party-pis {
  color: $bg-pis;
}

input.party-radio-pis:checked~label.party-radio-pis {
  background-color: $bg-pis;
}

.party-pis::-webkit-slider-thumb {
  background-color: $bg-pis;
}

.party-pis::-moz-range-thumb {
  background-color: $bg-pis;
}

.control-row.party-pis {
  color: black;
  border-color: rgba($bg-pis, 0.4);
}

// Konfederacja
.party-konfederacja {
  color: $bg-konfederacja;
}

input.party-radio-konfederacja:checked~label.party-radio-konfederacja {
  background-color: $bg-konfederacja;
}

.party-konfederacja::-webkit-slider-thumb {
  background-color: $bg-konfederacja;
}

.party-konfederacja::-moz-range-thumb {
  background-color: $bg-konfederacja;
}

.control-row.party-konfederacja {
  color: black;
  border-color: rgba($bg-konfederacja, 0.4);
}

// KO
.party-ko {
  color: $bg-ko;
}

input.party-radio-ko:checked~label.party-radio-ko {
  background-color: $bg-ko;
}

.party-ko::-webkit-slider-thumb {
  background-color: $bg-ko;
}

.party-ko::-moz-range-thumb {
  background-color: $bg-ko;
}

.control-row.party-ko {
  color: black;
  border-color: rgba($bg-ko, 0.4);
}

// Polska 2050
.party-pl2050 {
  color: $bg-pl2050;
}

input.party-radio-pl2050:checked~label.party-radio-pl2050 {
  background-color: $bg-pl2050;
}

.party-pl2050::-webkit-slider-thumb {
  background-color: $bg-pl2050;
}

.party-pl2050::-moz-range-thumb {
  background-color: $bg-pl2050;
}

.control-row.party-pl2050 {
  color: black;
  border-color: rgba($bg-pl2050, 0.4);
}

// Trzecia Droga
.party-trzecia_droga {
  color: $bg-trzecia_droga;
}

input.party-radio-trzecia_droga:checked~label.party-radio-trzecia_droga {
  background-color: $bg-trzecia_droga;
}

.party-trzecia_droga::-webkit-slider-thumb {
  background-color: $bg-trzecia_droga;
}

.party-trzecia_droga::-moz-range-thumb {
  background-color: $bg-trzecia_droga;
}

.control-row.party-trzecia_droga {
  color: black;
  border-color: rgba($bg-trzecia_droga, 0.4);
}

// Lewica
.party-lewica {
  color: $bg-lewica;
}

input.party-radio-lewica:checked~label.party-radio-lewica {
  background-color: $bg-lewica;
}

.party-lewica::-webkit-slider-thumb {
  background-color: $bg-lewica;
}

.party-lewica::-moz-range-thumb {
  background-color: $bg-lewica;
}

.control-row.party-lewica {
  color: black;
  border-color: rgba($bg-lewica, 0.4);
}

// PSL
.party-psl {
  color: $bg-psl;
}

input.party-radio-psl:checked~label.party-radio-psl {
  background-color: $bg-psl;
}

.party-psl::-webkit-slider-thumb {
  background-color: $bg-psl;
}

.party-psl::-moz-range-thumb {
  background-color: $bg-psl;
}

.control-row.party-psl {
  color: black;
  border-color: rgba($bg-psl, 0.4);
}

// Mniejszość Niemiecka
.party-mn {
  color: $bg-mn;
}

input.party-radio-mn:checked~label.party-radio-mn {
  background-color: $bg-mn;
}

.party-mn::-webkit-slider-thumb {
  background-color: $bg-mn;
}

.party-mn::-moz-range-thumb {
  background-color: $bg-mn;
}

.control-row.party-mn {
  color: black;
  border-color: rgba($bg-mn, 0.4);
}

// Pozostali
.party-pozostali {
  color: $bg-pozostali;
}

input.party-radio-pozostali:checked~label.party-radio-pozostali {
  background-color: $bg-pozostali;
}

.party-pozostali::-webkit-slider-thumb {
  background-color: $bg-pozostali;
}

.party-pozostali::-moz-range-thumb {
  background-color: $bg-pozostali;
}

.control-row.party-pozostali {
  color: black;
  border-color: rgba($bg-pozostali, 0.4);
}

// Pozostali
.party-niezdecydowani {
  color: $bg-niezdecydowani;
}

input.party-radio-niezdecydowani:checked~label.party-radio-niezdecydowani {
  background-color: $bg-niezdecydowani;
}

.party-niezdecydowani::-webkit-slider-thumb {
  background-color: $bg-niezdecydowani;
}

.party-niezdecydowani::-moz-range-thumb {
  background-color: $bg-niezdecydowani;
}

.control-row.party-niezdecydowani {
  color: black;
  border-color: rgba($bg-niezdecydowani, 0.4);
}

// Group 1
.party-group1 {
  color: $bg-group1;
}

input.party-radio-group1:checked~label.party-radio-group1 {
  background-color: $bg-group1;
}

.party-group1::-webkit-slider-thumb {
  background-color: $bg-group1;
}

.party-group1::-moz-range-thumb {
  background-color: $bg-group1;
}

// Group 2
.party-group2 {
  color: $bg-group2;
}

input.party-radio-group2:checked~label.party-radio-group2 {
  background-color: $bg-group2;
}

.party-group2::-webkit-slider-thumb {
  background-color: $bg-group2;
}

.party-group2::-moz-range-thumb {
  background-color: $bg-group2;
}

// Cookie consent

.cookie-banner {
  background-color: $bg-layout4 !important;
  box-shadow: 0 0 1px darken($bg-layout4, 20);
  opacity: 0.98;
}

.btn.cookie-accept-button {
  background-color: white;
}

.btn.cookie-accept-button:hover {
  background-color: darken(white, 10);
}

.cookie-decline-button {
  background-color: darken($bg-layout4, 8);
}

.cookie-decline-button:hover {
  background-color: darken($bg-layout4, 12);
}

// Single list explanation

.example-chart {
  padding-bottom: 1em;
}

.example-chart .legend {
  text-align: center;
  margin-left: 120px;
}

.example-chart .seat-chart,
.example-chart .score-chart {
  height: 8em;
}

.example-chart.example-spoiler .seat-chart,
.example-spoiler.example-chart .score-chart {
  height: 5em;
}

.example-chart.example-smaller .seat-chart {
  height: 6rem;
}

.example-chart.example-smaller .score-chart {
  height: 5rem;
}

.example-chart.example-tiny .seat-chart {
  height: 4.5rem;
}

.chapter {
  padding: 1em 2em;
}

@media (max-width: 575px) {
  .example-chart .legend {
    text-align: center;
    margin-left: 0;
  }

  .example-chart .legend span {
    font-size: 0.7rem;
  }

  .chapter {
    padding: 0;
  }

  .chapter>div {
    padding: 1rem;
  }
}

.chapter-0 {
  background: lighten($bg-layout2, 7);
}

.chapter-1 {
  background: lighten($bg-layout2, 6);
}

.chapter-2 {
  background: lighten($bg-layout2, 5);
}

.chapter-3 {
  background: lighten($bg-layout2, 4);
}

.chapter-4 {
  background: lighten($bg-layout2, 3);
}

.chapter-5 {
  background: lighten($bg-layout2, 2);
}

.chapter-6 {
  background: lighten($bg-layout2, 1);
}

.chapter-7 {
  background: lighten($bg-layout2, 0);
}

.chapter-8 {
  background: darken($bg-layout2, 1);
}

.dhondt-example .party-a {
  background-color: rgba(#c32760, 0.05);
}

.dhondt-example .party-b {
  background-color: rgba(#1E88E5, 0.05);
}

.dhondt-example .party-c {
  background-color: rgba(#92e51e, 0.05);
}

.dhondt-example-constituency-list {
  font-size: 0.8rem;
}

.chapter a {
  color: darken($bg-layout4, 25);
}

.chapter a:hover {
  color: $bg-layout4;
  transition: color 0.2s ease;
}

.chapter aside {
  font-size: 0.8em;
  background-color: rgba(gray, 0.1);
  padding: 1em 1em 1em 1em;
  margin-bottom: 1em;
}

.chapter aside p,
.chapter aside ul,
.chapter figure ol {
  margin-bottom: 0;
}

.chapter figure {
  font-size: 0.9em;
  background-color: rgba($bg-layout4, 0.05);
  padding: 1em 1em 1em 1em;
  margin-bottom: 1em;
}